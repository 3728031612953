<!--Mockflow Design-->
<template>
    <v-container>
        <v-skeleton-loader ref="skeleton" type="table" v-if="isStaffLoading">
        </v-skeleton-loader>
        <v-row
        v-else>
            <v-col
            class="mx-auto"
            cols="12"
            sm="6">
            <div class="d-flex justify-space-between mb-2">
                <v-btn
                    color="primary"
                    @click="()=>{
                        this.$router.go(-1);
                    }">
                    <v-icon>
                        mdi-arrow-left-bold
                    </v-icon>
                </v-btn>

            </div>
                <v-card class="pt-3 pl-3" v-if="!isStaffLoading">
                    <div v-if="this.$store.getters.getRole.includes('developer')" class="d-flex justify-end mx-2"> 
                        <v-btn color="primary" :loading="api.isLoading" @click="redirectDirectLogin">
                            Login
                        </v-btn>
                    </div>
                    <div
                        class="d-flex justify-end mx-2">
                        <v-btn class="ma-3" @click="editStaff" color="primary"  v-if="this.$store.getters.getRole.includes('operation')">
                            Edit
                        </v-btn>
                    </div>
           
                    <div class="d-flex justify-space-between">
                        <v-icon size="100" class="ml-5"
                        color="primary">
                            fa-user 
                        </v-icon>
                    </div>
                    <v-card-title>
                        {{ staff.user_name }} 
                    </v-card-title>
                    
                    <p class="mt-3 ml-3">
                        <v-icon small class="mx-2">
                            fa-phone
                        </v-icon>
                        {{ staff.mobile }}
                    </p>
                    <p class="mt-3 ml-3">
                        <v-icon small class="mx-2">
                            mdi-email
                        </v-icon>
                        {{ staff.email }}
                    </p>
                    <div
                        class="mt-3 ml-3">
                        <span
                            class="text-h7">
                            Remark :
                        </span>
                    </div>
                    <div
                        class="my-3 mx-3">
                        <v-row>
                            <v-col
                                cols="12"
                                sm=12>
                                <span>
                                    {{ (staff.staff_remark!==null && staff.staff_remark!=="")?staff.staff_remark:"No remark recorded" }}
                                </span>

                            </v-col>
                        </v-row>
                    </div>           
                    <hr/>
                    <div class="pb-3" v-if="this.teamDescription!=null">
                        <p class="mt-3 ml-3 font-weight-bold" v-if="this.teamDescription!=null">
                        
                            Team leader ( {{ teamDescription.name }})
                        </p>
                        <!-- <p class="mt-3 ml-3 pb-4" v-if="this.teamDescription!=null">
                            {{ teamDescription.name }}
                        </p> -->
                        <p class="mt-3 ml-3" v-if="this.teamDescription!=null">
                            <v-icon small class="mx-2">
                                fa-users
                            </v-icon> 
                            {{ leader.fname }} {{ leader.lname }}
                        </p>
                        <p class="ml-3">
                            <v-icon small class="mx-2">
                                mdi-email
                            </v-icon>
                            {{ leader.email }}
                        </p>
                    </div>
                    <div v-else>
                        <p class="mt-3 ml-3 pb-4">
                            <v-icon small class="mx-2">
                                fa-users
                            </v-icon>  
                            No team assigned 
                        </p>
                    </div>
                </v-card>

                <v-card class="my-3">
                    <div
                        v-if="staffRole!=null">
                       <v-data-table :items="staffRole" :headers="headersRole">
                            <template v-slot:item.role="{item}">
                                <!-- <span
                                    v-if="item.role!=null">
                                    {{ (item.role=='vision_admin')?"admin":item.role.replaceAll('_',' ')}}
                                </span> -->
                                {{ item.role.role.replaceAll("_"," ")+" ( "+item.role.platform+" )"}}
                                <!-- {{ item.role+" ( "+item.platform+" )" }} -->
                            </template>
                            <template v-slot:top>
                                <v-toolbar class="secondary white--text">
                                    <v-row>
                                        <v-toolbar-title class="ml-1">
                                            Role
                                        </v-toolbar-title>
                                        <v-spacer>
                                        </v-spacer>
                                        <!-- <div class="d-flex justify-end">
                                            <v-btn small color="primary" v-if="rolesAllowedEditUserDetail.some(role=>$store.getters.getRole.includes(role))" :to="{name:'PageStaffRole',params:{'id':$router.history.current.params.id}}" class="mx-2">
                                                Edit
                                            </v-btn>
                                        </div> -->
                                    </v-row>
                            </v-toolbar>
                            </template>
                       </v-data-table>

                    </div>                    
                </v-card>
                <v-skeleton-loader ref="skeleton" type="table" v-if="isCompanyLoading">

                </v-skeleton-loader>
                <v-card class="my-3">
                    <v-data-table
                    v-if="companies!=null"
                    :headers="headersCompanies"
                    :items="companies"
                    class="elevation-1">
                        <template v-slot:top>
                            <v-toolbar class="secondary white--text" >
                            <v-row class="">
                                <v-col>
                                    <v-row>
                                        <v-toolbar-title class="ml-1" >
                                            Companies in-charge
                                        </v-toolbar-title>
                                        <v-spacer>
                                        </v-spacer>
                                        <div class="d-flex justify-end mr-1"  v-if="rolesAllowedMassAssignation.some(role=>$store.getters.getRole.includes(role))">
                                            <JsonCSV :data="downloadCompanies">
                                                <v-btn color="primary" small class="mx-2">
                                                    Download
                                                </v-btn>
                                            </JsonCSV>
                                            <div
                                            v-if="tempStaffRole!=null"
                                            >
                                                <!-- {{ tempStaffRole }}
                                                {{ rolesAllowedSeeCompanies.some(role=>tempStaffRole.includes(role)) }}
                                                {{ rolesAllowedEditUserDetail.some(role=>$store.getters.getRole.includes(role)) }} -->
                                                <v-btn small  color="primary" class=""
                                                v-if="rolesAllowedSeeCompanies.some(role=> tempStaffRole.map(item=>item.role).includes(role)) && rolesAllowedEditUserDetail.some(role=>$store.getters.getRole.includes(role))" :to="{name:'PagePICCompaniesAssign',params:{'id':$router.history.current.params.id}}">
                                                        Assign
                                                </v-btn>
                                            </div>
                                        </div>

                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-toolbar>
                        </template>
                    </v-data-table>

                    <v-card-title v-else>
                        No companies assigned
                    </v-card-title>
                </v-card>

                <v-card class="my-3" v-if="!isTaskLoading">
                    <div>
                        <div>
                            <v-data-table
                            v-if="task!=null"
                            :headers="headerTask"
                            :items="task"
                            class="elevation-1"
                            >

                            <template v-slot:top>
                                <v-toolbar class="white--text secondary">
                                    <v-row no-gutters >
                                        <v-col class="ml-3">
                                            <v-row>
                                                <v-toolbar-title class="ml-1">
                                                    Task
                                                </v-toolbar-title>
                                                <v-spacer>
                                                </v-spacer>
                                                <div class="d-flex justify-end" v-if="rolesAllowedMassAssignation.some(role=>$store.getters.getRole.includes(role)) ">
                                                    <JsonCSV :data="downloadTask">
                                                        <v-btn small color="primary">
                                                            Download
                                                        </v-btn>
                                                        </JsonCSV>

                                                    <v-btn
                                                    small
                                                    :to="{name:'PageTaskMassAssign',params:{id:$router.history.current.params.id}}"
                                                    class="mx-3" 
                                                    color="primary"  
                                                    v-if="rolesAllowedMassAssignation.some(role=>$store.getters.getRole.includes(role)) ">
                                                        Assign
                                                    </v-btn>

                                                </div>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-toolbar>
                            </template>
                            </v-data-table>
                            <!-- <v-card-title v-else>
                                No task assigned
                            </v-card-title> -->
                        </div>
                    </div>
                </v-card>


                <v-card class="my-3" v-if="!isTeamLoading">
                    <v-card>
                        <v-toolbar class=" white--text secondary">
                            <v-row no-gutters class="mt-3 ">
                                <v-col class="ml-3">
                                    <v-row>
                                        Team members ( {{ teamDescription.name }})
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-toolbar>
                        <v-data-table
                        :items = "this.team"
                        :headers = "this.headersTeam"
                        class="elevation--1">
                        <template v-slot:item.name="{item}">
                            {{ item.fname }} {{ item.lname }}
                        </template>
                        </v-data-table>
                    </v-card>
                </v-card>
             

                <ALoader
                :api="this.api"/>
                <v-card class="my-3"
                v-if="this.remark!=null">
                    <v-card-title class="secondary white--text">
                        Remark
                    </v-card-title>
                    <v-data-table
                    :items="remark"
                    :headers="headerRemark"
                    class="elevation--1">
                    </v-data-table>
                </v-card>
                    
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import store from '../../store';
export default{
    props:[
        'staffIndividual'
    ],
  
    data: () => ({
        store : store,
        isStaffLoading :true,
        isCompanyLoading :true,
        isTaskLoading:true,
        isTeamLoading:true,
        teamDescription:null,
        leader:null,
        team:null,
        task:null,
        downloadTask:null,
        staffRole:null,
        staff:null,
        rolesAllowedEditUserDetail:['developer','operation'],
        rolesAllowedMassAssignation:['developer','operation','vision_admin'],
        rolesAllowedSeeCompanies:['developer','operation','vision_admin','salesperson','customer_service','sales_manager','marketing','sales_team_lead'],
        api:{
            isSuccesful:false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
        },
        expanded:[],
        headersRole:[
            {
                text:'Role',
                value:'role',
            }
        ],
        headersTeam:[
            {
                text:'Name',
                value:'name',
                align:'start',
            },{
                text:'Email',
                value:'email',
            },
            // {
            //     text:'Team leader',
            //     value:'teamlead',
            // },
            // {
            //     text:'Team leader email',
            //     value:'email'
            // }
        ],
        headersCompanies:[
            {
                text:'Name',
                value:'company_name',
                align:'start',
            },{
                text:'Subscription status',
                value:'verification',
            },
            // {
            //     text:'Company status',
            //     value:'verification',
            // }
        ],
        headerTask:[
            {
                text:'Company',
                value:'name',
            },
            {
                text:'Action',
                value:'task_category',
            },
            {
                text:'Description',
                value:'task_action',
            },
            {
                text:'Date',
                value:'created_at',
            },
            {
                text:'priority',
                value:'priority',
            },
            {
                text:'Assigned by',
                value:'assigned_by_name',
            },
            // {
            //     text:'Done',
            //     value:'task_status',
            // },
        ],
        VisionRoleOptions:[
            {
               text:'No access to vision',
               value:'disabled'
            },
            {
               text:'Salesperson',
               value:'salesperson'
            },
            {
               text:'Customer service',
               value:'customer_service'
            },
            {
               text:'Operation',
               value:'operation'
            },
            {
               text:'Admin',
               value:'vision_admin',
            },
            {
               text:'Sales team lead',
               value:'sales_team_lead',
            },
            {
               text:'Sales manager',
               value:'sales_manager'
            },
         ],
        headerRemark:[
            {
                text:'Company',
                value:'companyName',
                align:'start',
                sortable:false,

            },
            {
                text:'remark',
                value:'remark',
                sortable:false,

            },
            {
            text:'Remark at',
                value:'created_at',
                sortable:false,

            }
        ],
        tempStaffRole:null,
        companies:null,
        downloadCompanies:null,
        remark:null,
    }),
    created(){
        this.api.callbackReset = () => 
        {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {
            if(resp.class=='getUserFullName'){
                this.staff = resp.data[0];
                this.isStaffLoading = false;
            }
            if(resp.class=='getCompaniesClientActiveAssigned'){
                this.companies = resp.data.companies;
                this.downloadCompanies = resp.data.companies.map(obj=>({
                    "Company":obj.company_name,
                    "Plan Name":obj.plan_name,
                    "Subscription Date":this.$moment(obj.created_at).format("LLL")
                }));
                this.isCompanyLoading = false;
            }   
            if(resp.class=="getTaskByUser"){
                this.task = resp.data;
                this.downloadTask = resp.data.map(obj=>({
                    "Company":obj.name,
                    "Action":obj.task_category,
                    "Description":obj.task_action,
                    "Assigned Date":this.$moment(obj.created_at).format("LLL"),
                    "Assigned By":obj.assigned_by_name,
                }))
                this.isTaskLoading = false;
            }
            if(resp.class =="getIndividualTeamById"){
                    this.teamDescription = resp.data.team[0];
                    this.team = resp.data.members;
                    this.leader = resp.data.leader[0];
                    this.isTeamLoading = false;
            }
            if(resp.class==="getUserRole") {
                let tempRole = resp.data.map((obj)=>({
                    "role":obj,
                }));

                this.staffRole = tempRole;
               
                let tempStaffRole = this.$_.clone(this.staffRole);
                this.tempStaffRole = tempStaffRole.map(item=>item.role);
            }


            if(resp.status == "success")
            {
                if(this.api.url == process.env.VUE_APP_SERVER_API+"/company/get/user/"+this.staff.id+"?withSubscription=true")
                {
                    this.$store.commit('updateCompany',resp.data)
                    this.companies = resp.data;
                    this.isCompanyLoading = false;
                }
                if(resp.class =="getIndividualTeamById"){
                    this.teamDescription = resp.data.team[0];
                    this.team = resp.data.members;
                    this.leader = resp.data.leader[0];
                }
                // if(resp.class=="getTaskByUser"){
                //     this.task = resp.data;
                // }
                if(resp.class=="VisionLogin"){
                    this.$store.dispatch("login",resp.data);
                    return 1;
                }
                this.api.isLoading = false;
               
             
            }
        this.api.isLoading = false;
        }
    },
    mounted(){
        this.fetch();
    },
    methods:{
        closeDetail(){
            this.$store.dispatch("clearCompany");
            this.$router.go(-1);
        },
        editStaff(){
            this.$router.push({name:'PageStaffUpdate',params:{'id':this.$router.history.current.params.id}});
        },
        fetch(){
            let fetchUserApi = this.fetchUser();
            let fetchCompanyApi = this.fetchCompany();
            let fetchTaskApi = this.fetchTask();
            let fetchTeamApi = this.fetchTeam();
            this.$axios.all([
                this.$api.fetch(fetchUserApi),
                this.$api.fetch(this.fetchUserRole()),
                this.$api.fetch(fetchCompanyApi),
                this.$api.fetch(fetchTeamApi),
                this.$api.fetch(fetchTaskApi),
            ])
            return ;
        },
        fetchUserRole() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API.replace("v1","v2")+"/users/role/"+this.$router.history.current.params.id+"?isVision=true";
            return tempApi;
        },
        fetchUser(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/user/id/"+this.$router.history.current.params.id+"/fullname";
            return tempApi;
        },
        fetchCompany(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "get";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/pic/company?userId="+this.$router.history.current.params.id;
            // tempApi.url = process.env.VUE_APP_SERVER_API+"/company/get/user/"+this.$router.history.current.params.id+"?withSubscription=true";
            return tempApi
        },
        fetchTeam(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/team/id/"+this.$router.history.current.params.id;
            return tempApi;
        },
        fetchTask(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/task/user/"+this.$router.history.current.params.id;
            return tempApi;
        },
        fetchStaffDetail(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/staff/login/id/"+this.$router.history.current.params.id;
            return tempApi;

        },
        redirectDirectLogin(){
            let fetchStaffDetailApi = this.fetchStaffDetail();
            this.$api.fetch(fetchStaffDetailApi).then(()=>{
                this.$router.push({name:'PageGuestHome'});
            });
        }
    }
}
</script>